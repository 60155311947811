.deploy-model-modal {
    width: 40%;
}

.deploy-model-modal-content {
    height: 70px;
}

.collection-select {
    position: absolute !important;
    left: 24px;
    width: calc(100% - 48px);
}
.deployment-modal.MuiDialog-paperWidthSm {
    width: 700px;
    max-width: 80%;
}

.deployment-modal-content {
    height: 80vh;
}

#deploy_chart {
    height: 100%;
}
.App {
    display: grid;
    grid-template: "toolbar toolbar toolbar"
    "chart chart sidepanel"
    "previewpanel previewpanel sidepanel";
    grid-template-columns: 52px auto 350px;
    grid-template-rows: 50px calc(80vh - 50px) 20vh;
}

.App.deploy_mode {
    display: grid;
    grid-template: "toolbar"
    "heatmap";
    grid-template-rows: 50px calc(100vh - 50px);
}

.toolbar {
    grid-area: toolbar;
}

.chart-component {
    grid-area: chart;
}

.side-panel {
    grid-area: sidepanel;
}

.preview-panel {
    grid-area: previewpanel;
}

.heatmap-component {
    grid-area: heatmap;
}

.loading-fade {
    grid-area: 2 / 1 / 4 / 3;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
}

.loading-fade.fullscreen {
    grid-area: heatmap;
}

.loading-fade.visible {
    display: flex;
}
.save-model-modal {
    width: 40%;
}

.custom-input {
    width: 100%;
}

.save-model-modal-content {
    height: 70px;
}
.main-panel {
    padding: 10px 25px;
}

.form-container {
    margin-top: 10px;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item.slider-container {
    padding: 0 8px;
}

.custom-select {
    width: 100%;
}
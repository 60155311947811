.root {
    position: relative;
}

.static.MuiCircularProgress-colorPrimary {
    color: #3498db;
}

.dynamic.MuiCircularProgress-colorPrimary {
    color: #f3f3f3;
    position: absolute;
    left: 0;
}
.toolbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e3eb;
    color: #131722;
}

.left-align, .right-align {
    height: 100%;
    display: flex;
    align-items: center;
}

.toolbar-btn {
    margin: 0 5px;
}
.heatmap-component {
    padding: 10px 10px 0 10px;
}

.heatmap {
    display: grid;
    grid-template: "heatmap legend";
    grid-template-columns: auto 80px;
}

.heatmap-container {
    grid-area: heatmap;
}

.heatmap-legend {
    grid-area: legend;
    width: 100%;
    height: 100%;
    max-height: 70vh;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    justify-content: space-evenly;
}

.cell {
    width: 35px;
    border: 1px solid #e0e3eb;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%
}

.row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}